body {
  color: whitesmoke;
  background-color: whitesmoke;
}

#page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

#box {
  color: white;
  background-color: white;
  box-shadow: 0 0 15px grey;
  width: 400px;
}

#grid {
  margin: 0;
  height: 100%;
  width: 100%;
}

#form {
  width: 100%;
  height: 100%;
}

.text {
  width: 65% !important;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: cornflowerblue;;
}

.text > label {
  color: cornflowerblue;
  font: 400 13.3333px Arial
}

.button {
  margin-top: 20px !important;
}

.lastTextField {
  padding-bottom: 25px;
}

.lastButton {
  margin-bottom: 20px !important;
}

.textAlignCenter {
  text-align: center;
}

.textAlignEnd {
  text-align: end;
}

.textAlignStart {
  text-align: start;
}

.row {
  margin-bottom: 10px;
}

.text > input, .text > label {
  margin-left: 10px;
}

#fileLabel {
  width : 170px;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  padding-top: 20px;
}

.inputField {
  width: 60%;
}

#submitForm {
  background-color: blue;
  color:white;
  border: 0;
  border-radius: 5px;
  width: 101px;
  height: 36px;
  font-size: 0.875rem;
  cursor: pointer;
}

#submitForm:hover {
  background-color: darkblue;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;;
}

.reset-button {
  margin-left: auto !important;
  margin-right: 0 !important;
  display: block !important;
  margin-top: 0.5rem !important;
}

.reset-button * {
  display: grid !important;
  align-items: center !important;
}