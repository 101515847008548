.App {
  text-align: center;
}

.portuguese-auth-color {
  background-color: #5d82a9 !important;
}

.link {
  color: white;
  text-decoration: none;
}

.buttonLink {
  margin-top: 30px !important
}
