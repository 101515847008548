  .ocr {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-between;	
    justify-items: center;
  }
  
  .result {
    grid-column-start: 1;
    grid-column-end: 5;
    padding: 16px;
  }
  
  .result .result_content {
    display: flex;
    border-bottom: 1px solid #aaedff;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
  
  
.uploaderAction{
  padding: 16px;
  display:grid;
  position: relative;
}

#page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

#uploaderBox {
  background-color: white;
  width: 400px;
  padding: 0px;
}

#grid {
  margin: 0;
  height: 100%;
  width: 100%;
}

#uploaderBtn{
    color: white;
}

.divider{
  height: 2px !important;
  background-color: cornflowerblue !important;
  margin-left: 16px !important;
  margin-bottom: 25px !important;
}

.rightSide{
  margin-top: 28px !important;
  padding: 8px !important;
}

.formLink{
  color: cadetblue;
  font-size: small;
}

.ocrForm{
  color: black;
}